import {
  AiWorkflowRun,
  AiRequestLogEntry,
  AiNpcWorkflowContext,
  AiWorkflowRunListResult,
  AiWorkflowRunExecutedNode,
  JSONSchema7,
} from "@worldwidewebb/client-ai";
import { decodeTime } from "ulid";
import { useCallback } from "react";
import { aiApi } from ".";

export interface AiEditorWorkflowRunExecutedNode extends AiWorkflowRunExecutedNode {
  inputs: Record<string, unknown>;
  outputs: Record<string, unknown>;
  timestamp: number;
}

export interface AiEditorWorkflowRun extends AiWorkflowRun {
  errorData?: { [key: string]: unknown };
  nodes: AiEditorWorkflowRunExecutedNode[];
}

const useAi = () => {
  const getAiCompletionSchema = useCallback(async (workflowName: string): Promise<JSONSchema7> => {
    try {
      const response = await aiApi.getAiCompletionSchema(workflowName);
      const { data: schema } = response;

      return schema;
    } catch (error) {
      console.error(error);
      throw error;
    }
  }, []);

  const getWorkflowRun = useCallback(async (workflowId: string): Promise<AiEditorWorkflowRun> => {
    try {
      const response = await aiApi.getAiEditorAiRun(workflowId);
      const { data: workflowRun } = response;

      const aiEditorWorkflowRun: AiEditorWorkflowRun = {
        ...(workflowRun as AiEditorWorkflowRun),
      };

      const resultNode = workflowRun.nodes.find((node) => node.nodeId === "main");

      const resultInputs = resultNode?.inputs as Record<string, unknown>;
      if (resultInputs?.type === "error") {
        aiEditorWorkflowRun.errorData = resultInputs.error as { [key: string]: unknown };
      }

      aiEditorWorkflowRun.nodes = workflowRun.nodes.filter(
        (node) => node.nodeId && node.nodeId !== "workflow" && node.nodeId !== "main"
      ) as AiEditorWorkflowRunExecutedNode[];

      return aiEditorWorkflowRun;
    } catch (error) {
      console.error(error);
      throw error;
    }
  }, []);

  const getWorkflowRuns = useCallback(
    async (aiId: string, offset?: number, limit?: number): Promise<AiWorkflowRunListResult[]> => {
      try {
        const response = await aiApi.getAiEditorAiRuns(aiId, limit, offset);
        const { data: workflows } = response;

        return workflows.sort((a, b) => decodeTime(b.workflowRunId) - decodeTime(a.workflowRunId));
      } catch (error) {
        console.error(error);
        throw error;
      }
    },
    []
  );

  const getRunRequestLog = useCallback(async (systemId: string, nodeId: string): Promise<AiRequestLogEntry> => {
    try {
      const response = await aiApi.getAiEditorAiRunRequestLog(systemId, nodeId);
      const { data: entry } = response;

      return entry;
    } catch (error) {
      console.error(error);
      throw error;
    }
  }, []);

  const executeWorkflow = useCallback(async (context: AiNpcWorkflowContext) => {
    try {
      return await aiApi.createWorkflowExecution(context);
    } catch (error) {
      console.error(error);
      throw error;
    }
  }, []);

  return {
    getAiCompletionSchema,
    getWorkflowRun,
    getWorkflowRuns,
    getRunRequestLog,
    executeWorkflow,
  };
};

export default useAi;
